import React from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  Annotation,
} from "react-simple-maps";

const geoUrl =
  "https://raw.githubusercontent.com/deldersveld/topojson/master/world-countries.json";

const MapChart = () => {
  return (
    <ComposableMap className="map" projection="geoNaturalEarth1" width="1300">
      <Geographies
        geography={geoUrl}
        fill="#D6D6DA"
        stroke="#FFFFFF"
        strokeWidth={0.5}
      >
        {({ geographies }) =>
          geographies.map((geo) => {
            return (
              <Geography
                key={geo.rsmKey}
                geography={geo}
                style={{
                  default: {
                    fill:
                      geo.rsmKey === "geo-6"
                        ? "#fcfdfe"
                        : geo.rsmKey === "geo-56" ||
                          geo.rsmKey === "geo-3" ||
                          geo.rsmKey === "geo-48" ||
                          geo.rsmKey === "geo-31" ||
                          geo.rsmKey === "geo-104"
                        ? "#ff9195"
                        : "#D6D6DA",
                    outline: "none",
                  },
                  hover: {
                    fill: geo.rsmKey === "geo-6" ? "#fcfdfe" : "#ff9195",
                    outline: "none",
                  },
                  pressed: {
                    fill: geo.rsmKey === "geo-6" ? "#fcfdfe" : "#ff565c",
                    outline: "none",
                  },
                }}
              />
            );
          })
        }
      </Geographies>
      <Annotation
        subject={[55.2708, 25.2048]}
        dx={20}
        dy={30}
        connectorProps={{
          stroke: "#4863a5",
          strokeWidth: 1.5,
          strokeLinecap: "round",
        }}
      >
        <text
          x="5"
          y="10"
          textAnchor="start"
          alignmentBaseline="middle"
          fill="#4863a5"
          fontSize="16px"
        >
          {"Dubai, UAE"}
        </text>
      </Annotation>

      <Annotation
        subject={[116.4074, 39.9042]}
        dx={-25}
        dy={-30}
        connectorProps={{
          stroke: "#4863a5",
          strokeWidth: 1.5,
          strokeLinecap: "round",
        }}
      >
        <text
          x="-5"
          y="0"
          textAnchor="end"
          alignmentBaseline="middle"
          fill="#4863a5"
        >
          {"Beijing, China"}
        </text>
      </Annotation>

      <Annotation
        subject={[31.2357, 30.0444]}
        dx={-15}
        dy={-10}
        connectorProps={{
          stroke: "#4863a5",
          strokeWidth: 1.5,
          strokeLinecap: "round",
        }}
      >
        <text
          x="-5"
          y="0"
          textAnchor="end"
          alignmentBaseline="middle"
          fill="#4863a5"
        >
          {"Cairo, Egypt"}
        </text>
      </Annotation>
      <Annotation
        subject={[2.3522, 48.8566]}
        dx={-15}
        dy={-10}
        connectorProps={{
          stroke: "#4863a5",
          strokeWidth: 1.5,
          strokeLinecap: "round",
        }}
      >
        <text
          x="-5"
          y="0"
          textAnchor="end"
          alignmentBaseline="middle"
          fill="#4863a5"
        >
          {"Paris, France"}
        </text>
      </Annotation>
      <Annotation
        subject={[-99.132, 19.4326]}
        dx={-15}
        dy={-10}
        connectorProps={{
          stroke: "#4863a5",
          strokeWidth: 1.5,
          strokeLinecap: "round",
        }}
      >
        <text
          x="-9"
          y="0"
          textAnchor="end"
          alignmentBaseline="middle"
          fill="#4863a5"
        >
          {"Mexico City, Mexico"}
        </text>
      </Annotation>
    </ComposableMap>
  );
};

export default MapChart;
