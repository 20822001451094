import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import PageWrapper from "../components/PageWrapper";

import team1 from "../assets/extra/team/ab.jpg";
import team4 from "../assets/extra/team/nv.jpg";
// import team6 from "../assets/extra/team/ayb.jpg";
import team7 from "../assets/extra/team/jp.jpg";
// import team9 from "../assets/extra/team/jc.png";
import team10 from "../assets/extra/team/kk.png";
import team11 from "../assets/extra/team/svy.png";
import team12 from "../assets/extra/team/tx.png";
import MapChart from "../added-libs/MapChart.js";

import team13 from "../assets/extra/team/zm.png";

import team14 from "../assets/extra/team/aso.png";
import team15 from "../assets/extra/team/ies.png";
import team16 from "../assets/extra/team/mbt.png";
import team17 from "../assets/extra/team/rs-1.png";
import team18 from "../assets/extra/team/fs.jpeg";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../addedcss/carouselStyles.css";
import { navigate } from '@reach/router'

import { Carousel } from "react-responsive-carousel";

import carouselimg1 from "../assets/extra/index/carousel-images/carousel-img1.png";
import carouselimg2 from "../assets/extra/index/carousel-images/carousel-img2.png";
import carouselimg3 from "../assets/extra/index/carousel-images/carousel-img3.png";
import carouselimg4 from "../assets/extra/index/carousel-images/carousel-img4.png";
import carouselimg5 from "../assets/extra/index/carousel-images/carousel-img5.png";
import carouselimg6 from "../assets/extra/index/carousel-images/carousel-img6.png";

import Timeline from "../components/HorizontalTimeline/Timeline.js";
import { GATSBY_URL } from "../constants";

const Product = () => {
  const iconClassName = "text-storm gr-hover-text-blue";

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        url: GATSBY_URL,
        path: "/about-us",
        page_type: "aboutUs",
        event: "page_view",
        title: "Company",
      });
    }
  }, []);

  useEffect(() => {
		navigate('/')
	}, [])
	return null

  return (
    <>
      <Helmet title="Company | Genify" defer={false} />
      <PageWrapper
        headerConfig={{
          theme: "light",
          align: "right",
          variant: "primary",
          isFluid: true,
          source: "genify",
          button: "null", // cta, account, null
        }}
        footerConfig={{
          style: "style1", //style1, style2
        }}
      >
        <div
          class="pt-13 pt-lg-24 pb-lg-24 bg-default-1"
          style={{ marginTop: "5%" }}
        >
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-lg-9 col-xl-8">
                <div class="section-title text-center px-md-12 mb-lg-17 mb-10">
                  <h1 class="gr-text-2 mb-7 mb-lg-8">Meet our team</h1>
                </div>
              </div>
            </div>
            <div
              class="row justify-content-left mb-lg-n15 mb-0"
              style={{ marginLeft: "5%" }}
            >
              <div
                class="col-lg-3 col-md-4 col-xs-6 mb-lg-15 mb-13"
                style={{ maxWidth: "45%" }}
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-delay="500"
              >
                <div class="team-card">
                  <div class="card-image">
                    <img
                      src={team1}
                      alt="alex's image"
                      class="w-75"
                      style={{ borderRadius: "5%", maxWidth: "200px" }}
                    />
                  </div>
                  <div class="card-text pt-9">
                    <h3 class="gr-text-8 mb-2">Alexandre Boulenger </h3>
                    <h4 class="gr-text-9 mb-0 line-height-1">
                      CEO, Engineering Lead
                    </h4>{" "}
                    <br />
                    <p class="gr-text-10 mb-0 line-height-1">
                      Giving direction for Genify and sourcing the latest tech
                    </p>
                    <a
                      href="https://www.linkedin.com/in/alexblg/"
                      target="blank"
                      className={iconClassName}
                    >
                      <i className="icon icon-logo-linkedin"></i>
                    </a>
                  </div>
                </div>
              </div>
              {/* <div
								class='col-lg-3 col-md-4 col-xs-6 mb-lg-15 mb-13'
								style={{ maxWidth: '45%' }}
								data-aos='fade-up'
								data-aos-duration='1500'
								data-aos-delay='500'>
								<div class='team-card'>
									<div class='card-image'>
										<img
											src={team2}
											alt=''
											class='w-75'
											style={{ borderRadius: '5%', maxWidth: '200px' }}
										/>
									</div>
									<div class='card-text pt-9'>
										<h3 class='gr-text-8 mb-2'>Georgy Achkouty </h3>
										<h4 class='gr-text-9 mb-0 line-height-1'>COO</h4> <br />
										<p class='gr-text-10 mb-0 line-height-1'>
											Ensuring client happiness from product specs to delivery
										</p>
										<a
											href='https://www.linkedin.com/in/georgyachkouty/'
											target='blank'
											className={iconClassName}>
											<i className='icon icon-logo-linkedin'></i>
										</a>
									</div>
								</div>
							</div> */}
              {/* <div
								class='col-lg-3 col-md-4 col-xs-6 mb-lg-15 mb-13'
								style={{ maxWidth: '45%' }}
								data-aos='fade-up'
								data-aos-duration='1500'
								data-aos-delay='500'>
								<div class='team-card'>
									<div class='card-image'>
										<img
											src={team3}
											alt=''
											class='w-75'
											style={{ borderRadius: '5%', maxWidth: '200px' }}
										/>
									</div>
									<div class='card-text pt-9'>
										<h3 class='gr-text-8 mb-2'>Saulet Mukhamadiyev</h3>
										<h4 class='gr-text-9 mb-0 line-height-1'>VP Business Development, UAE</h4>
										<p class='gr-text-10 mb-0 line-height-1'>
											Driving operations on the ground in the UAE
										</p>
										<a
											href='https://www.linkedin.com/in/saulet/'
											target='blank'
											className={iconClassName}>
											<i className='icon icon-logo-linkedin'></i>
										</a>
									</div>
								</div>
							</div> */}
              <Carousel />
              <div
                class="col-lg-3 col-md-4 col-xs-6 mb-lg-15 mb-13"
                style={{ maxWidth: "45%" }}
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-delay="500"
              >
                <div class="team-card">
                  <div class="card-image">
                    <img
                      src={team4}
                      alt="neel's image"
                      class="w-75"
                      style={{ borderRadius: "5%", maxWidth: "200px" }}
                    />
                  </div>
                  <div class="card-text pt-9">
                    <h3 class="gr-text-8 mb-2">Neel Vohra</h3>
                    <h4 class="gr-text-9 mb-0 line-height-1">
                      VP Business Development, Mexico
                    </h4>
                    <p class="gr-text-10 mb-0 line-height-1">
                      Driving Genify's Mexico market entry
                    </p>
                    <a
                      href="https://www.linkedin.com/in/neelvohra/"
                      target="blank"
                      className={iconClassName}
                    >
                      <i className="icon icon-logo-linkedin"></i>
                    </a>
                  </div>
                </div>
              </div>

              {/* <div
								class='col-lg-3 col-md-4 col-xs-6 mb-lg-15 mb-13'
								style={{ maxWidth: '45%' }}
								data-aos='fade-up'
								data-aos-duration='1500'
								data-aos-delay='500'> */}
              {/* <div class='team-card'>
									<div class='card-image'>
										<img
											src={team5}
											alt=''
											class='w-75'
											style={{ borderRadius: '5%', maxWidth: '200px' }}
										/>
									</div>
									<div class='card-text pt-9'>
										<h3 class='gr-text-8 mb-2'>Oubay Nahas</h3>
										<h4 class='gr-text-9 mb-0 line-height-1'>
											Business Development Strategist, UAE
										</h4>
										<p class='gr-text-10 mb-0 line-height-1'>
											Expanding Genify's client portfolio in the UAE
										</p>
										<a
											href='https://www.linkedin.com/in/oubaynahas/'
											target='blank'
											className={iconClassName}>
											<i className='icon icon-logo-linkedin'></i>
										</a>
									</div>
								</div>
							</div> */}
              {/* <div class="col-lg-3 col-md-4 col-xs-6 mb-lg-15 mb-13" style={{maxWidth: "45%"}} data-aos="fade-up"
              data-aos-duration="1500"
              data-aos-delay="500">
                <div class="team-card">
                  <div class="card-image">
                    <img src={team6} alt="" class="w-75" style={{ borderRadius: "5%", maxWidth: "200px" }} />
                  </div>
                  <div class="card-text pt-9">
                    <h3 class="gr-text-8 mb-2">Aymeric Bouvier</h3>
                    <h4 class="gr-text-9 mb-0 line-height-1">Business Development Strategist</h4> <br />
                    <p class="gr-text-10 mb-0 line-height-1">Exploring new market opportunities for Genify</p>
                    <a href="https://www.linkedin.com/in/aymeric-bouvier/" target="blank" className={iconClassName}>
                      <i className="icon icon-logo-linkedin"></i>
                    </a>
                  </div>
                </div>
              </div> */}
              <div
                class="col-lg-3 col-md-4 col-xs-6 mb-lg-15 mb-13"
                style={{ maxWidth: "45%" }}
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-delay="500"
              >
                <div class="team-card">
                  <div class="card-image">
                    <img
                      src={team7}
                      alt="JP's image"
                      class="w-75"
                      style={{ borderRadius: "5%", maxWidth: "200px" }}
                    />
                  </div>
                  <div class="card-text pt-9">
                    <h3 class="gr-text-8 mb-2">Jean-Paul Wenger</h3>
                    <h4 class="gr-text-9 mb-0 line-height-1">
                      Lead developer
                    </h4>{" "}
                    <br />
                    <p class="gr-text-10 mb-0 line-height-1">
                      Building Genify's infrastructure since day 1
                    </p>
                    <a
                      href="https://www.linkedin.com/in/jean-paul-wenger-0286ba63/"
                      target="blank"
                      className={iconClassName}
                    >
                      <i className="icon icon-logo-linkedin"></i>
                    </a>
                  </div>
                </div>
              </div>
              {/* <div
								class='col-lg-3 col-md-4 col-xs-6 mb-lg-15 mb-13'
								style={{ maxWidth: '45%' }}
								data-aos='fade-up'
								data-aos-duration='1500'
								data-aos-delay='500'> */}
              {/* <div class='team-card'>
									<div class='card-image'>
										<img
											src={team8}
											alt=''
											class='w-75'
											style={{ borderRadius: '5%', maxWidth: '200px' }}
										/>
									</div>
									<div class='card-text pt-9'>
										<h3 class='gr-text-8 mb-2'>Davide Liu</h3>
										<h4 class='gr-text-9 mb-0 line-height-1'>Data Scientist</h4> <br />
										<p class='gr-text-10 mb-0 line-height-1'>
											Enhancing Genify's state-of-the-art recommendation engine
										</p>
										<a
											href='https://www.linkedin.com/in/davide-liu-9a39a3123/'
											target='blank'
											className={iconClassName}>
											<i className='icon icon-logo-linkedin'></i>
										</a>
									</div>
								</div>
							</div> */}
              {/* <div class="col-lg-3 col-md-4 col-xs-6 mb-lg-15 mb-13" style={{maxWidth: "45%"}} data-aos="fade-up"
              data-aos-duration="1500"
              data-aos-delay="500">
                <div class="team-card">
                  <div class="card-image">
                    <img src={team9} alt="" class="w-75" style={{ borderRadius: "5%", maxWidth: "200px" }} />
                  </div>
                  <div class="card-text pt-9">
                    <h3 class="gr-text-8 mb-2">John Chan</h3>
                    <h4 class="gr-text-9 mb-0 line-height-1">Software Engineer</h4> <br />
                    <p class="gr-text-10 mb-0 line-height-1">Porting Genify's systems to the latest tech</p>
                    <div className="">
                      <a href="https://www.linkedin.com/" target="blank" className="text-storm gr-hover-text-blue">
                        <i className="icon icon-logo-linkedin" style={{ width: "50px" }}></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div> */}
              <div
                class="col-lg-3 col-md-4 col-xs-6 mb-lg-15 mb-13"
                style={{ maxWidth: "45%" }}
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-delay="500"
              >
                <div class="team-card">
                  <div class="card-image">
                    <img
                      src={team10}
                      alt="Khloud's imags"
                      class="w-75"
                      style={{ borderRadius: "5%", maxWidth: "200px" }}
                    />
                  </div>
                  <div class="card-text pt-9">
                    <h3 class="gr-text-8 mb-2">Khloud Khalid</h3>
                    <h4 class="gr-text-9 mb-0 line-height-1">
                      Chief Product Officer
                    </h4>{" "}
                    <br />
                    <p class="gr-text-10 mb-0 line-height-1">
                      Working hard to launch EZloan in Egypt and beyond
                    </p>
                    <div className="">
                      <a
                        href="https://www.linkedin.com/in/khloud-khalid-5a40b493/"
                        target="blank"
                        className="text-storm gr-hover-text-blue"
                      >
                        <i
                          className="icon icon-logo-linkedin"
                          style={{ width: "50px" }}
                        ></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="col-lg-3 col-md-4 col-xs-6 mb-lg-15 mb-13"
                style={{ maxWidth: "45%" }}
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-delay="500"
              >
                <div class="team-card">
                  <div class="card-image">
                    <img
                      src={team11}
                      alt="Sophia's image"
                      class="w-75"
                      style={{ borderRadius: "5%", maxWidth: "200px" }}
                    />
                  </div>
                  <div class="card-text pt-9">
                    <h3 class="gr-text-8 mb-2">Sophia Victoria Yushchenko</h3>
                    <h4 class="gr-text-9 mb-0 line-height-1">
                      Business Development Strategist
                    </h4>
                    <p class="gr-text-10 mb-0 line-height-1">
                      Upgrading Genify's strategy and marketing kit
                    </p>
                    <div className="">
                      <a
                        href="https://www.linkedin.com/in/sophia-victoria-yushchenko/"
                        target="blank"
                        className="text-storm gr-hover-text-blue"
                      >
                        <i
                          className="icon icon-logo-linkedin"
                          style={{ width: "50px" }}
                        ></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="col-lg-3 col-md-4 col-xs-6 mb-lg-15 mb-13"
                style={{ maxWidth: "45%" }}
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-delay="500"
              >
                <div class="team-card">
                  <div class="card-image">
                    <img
                      src={team12}
                      alt="Victoria's image"
                      class="w-75"
                      style={{ borderRadius: "5%", maxWidth: "200px" }}
                    />
                  </div>
                  <div class="card-text pt-9">
                    <h3 class="gr-text-8 mb-2">Victoria Xie</h3>
                    <h4 class="gr-text-9 mb-0 line-height-1">
                      Business Development Strategist
                    </h4>{" "}
                    <br />
                    <p class="gr-text-10 mb-0 line-height-1">
                      Sourcing the latest Fintech trends from China and feeding
                      into Genify's roadmap
                    </p>
                    <div className="">
                      <a
                        href="https://www.linkedin.com/in/tianren-xie-308a86171/"
                        target="blank"
                        className="text-storm gr-hover-text-blue"
                      >
                        <i
                          className="icon icon-logo-linkedin"
                          style={{ width: "50px" }}
                        ></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="col-lg-3 col-md-4 col-xs-6 mb-lg-15 mb-13"
                style={{ maxWidth: "45%" }}
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-delay="500"
              >
                <div class="team-card">
                  <div class="card-image">
                    <img
                      src={team13}
                      alt="zeyad's image"
                      class="w-75"
                      style={{ borderRadius: "5%", maxWidth: "200px" }}
                    />
                  </div>
                  <div class="card-text pt-9">
                    <h3 class="gr-text-8 mb-2"> Zeyad Mahran</h3>
                    <h4 class="gr-text-9 mb-0 line-height-1">
                      Data Scientist
                    </h4>{" "}
                    <br />
                    <p class="gr-text-10 mb-0 line-height-1">
                      At the sweet spot between ML and backend
                    </p>
                    <div className="">
                      <a
                        href="https://www.linkedin.com/in/zemahran/"
                        target="blank"
                        className="text-storm gr-hover-text-blue"
                      >
                        <i
                          className="icon icon-logo-linkedin"
                          style={{ width: "50px" }}
                        ></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="col-lg-3 col-md-4 col-xs-6 mb-lg-15 mb-13"
                style={{ maxWidth: "45%" }}
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-delay="500"
              >
                <div class="team-card">
                  <div class="card-image">
                    <img
                      src={team14}
                      alt="samir's image"
                      class="w-75"
                      style={{ borderRadius: "5%", maxWidth: "200px" }}
                    />
                  </div>
                  <div class="card-text pt-9">
                    <h3 class="gr-text-8 mb-2"> Ahmed Samir Ouf </h3>
                    <h4 class="gr-text-9 mb-0 line-height-1">
                      Software Engineer
                    </h4>{" "}
                    <br />
                    <p class="gr-text-10 mb-0 line-height-1">
                      Making sure the Genify tech stack is the most robust
                    </p>
                    <div className="">
                      <a
                        href="https://www.linkedin.com/"
                        target="blank"
                        className="text-storm gr-hover-text-blue"
                      >
                        <i
                          className="icon icon-logo-linkedin"
                          style={{ width: "50px" }}
                        ></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="col-lg-3 col-md-4 col-xs-6 mb-lg-15 mb-13"
                style={{ maxWidth: "45%" }}
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-delay="500"
              >
                <div class="team-card">
                  <div class="card-image">
                    <img
                      src={team15}
                      alt="ismail's image"
                      class="w-75"
                      style={{ borderRadius: "5%", maxWidth: "200px" }}
                    />
                  </div>
                  <div class="card-text pt-9">
                    <h3 class="gr-text-8 mb-2"> Ismail El-Sharkawy </h3>
                    <h4 class="gr-text-9 mb-0 line-height-1">
                      Software Engineer
                    </h4>{" "}
                    <br />
                    <p class="gr-text-10 mb-0 line-height-1">
                      Learning the ropes while still contributing to the EZloan
                      tech stack from day 1
                    </p>
                    <div className="">
                      <a
                        href="https://www.linkedin.com/in/ismailelsharkawy/"
                        target="blank"
                        className="text-storm gr-hover-text-blue"
                      >
                        <i
                          className="icon icon-logo-linkedin"
                          style={{ width: "50px" }}
                        ></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="col-lg-3 col-md-4 col-xs-6 mb-lg-15 mb-13"
                style={{ maxWidth: "45%" }}
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-delay="500"
              >
                <div class="team-card">
                  <div class="card-image">
                    <img
                      src={team16}
                      alt="mohammed's image"
                      class="w-75"
                      style={{ borderRadius: "5%", maxWidth: "200px" }}
                    />
                  </div>
                  <div class="card-text pt-9">
                    <h3 class="gr-text-8 mb-2"> Mohammed Ben-Thaier </h3>
                    <h4 class="gr-text-9 mb-0 line-height-1">
                      Senior Software Engineer
                    </h4>{" "}
                    <br />
                    <p class="gr-text-10 mb-0 line-height-1">
                      Bringing some soft dev wisdom to the table
                    </p>
                    <div className="">
                      <a
                        href="https://www.linkedin.com/in/mohammed-ben-thaier/"
                        target="blank"
                        className="text-storm gr-hover-text-blue"
                      >
                        <i
                          className="icon icon-logo-linkedin"
                          style={{ width: "50px" }}
                        ></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="col-lg-3 col-md-4 col-xs-6 mb-lg-15 mb-13"
                style={{ maxWidth: "45%" }}
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-delay="500"
              >
                <div class="team-card">
                  <div class="card-image">
                    <img
                      src={team17}
                      alt="ramsey's image"
                      class="w-75"
                      style={{ borderRadius: "5%", maxWidth: "200px" }}
                    />
                  </div>
                  <div class="card-text pt-9">
                    <h3 class="gr-text-8 mb-2">Ramsey Chahine</h3>
                    <h4 class="gr-text-9 mb-0 line-height-1">
                      Business Developer
                    </h4>
                    <p class="gr-text-10 mb-0 line-height-1">
                      The go-to person at Genify for bank clients.{" "}
                    </p>
                    <a
                      href="https://www.linkedin.com/in/ramsey-chahine-99035496/"
                      target="blank"
                      className={iconClassName}
                    >
                      <i className="icon icon-logo-linkedin"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div
                class="col-lg-3 col-md-4 col-xs-6 mb-lg-15 mb-13"
                style={{ maxWidth: "45%" }}
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-delay="500"
              >
                <div class="team-card">
                  <div class="card-image">
                    <img
                      src={team18}
                      alt="said's image"
                      class="w-75"
                      style={{ borderRadius: "5%", maxWidth: "200px" }}
                    />
                  </div>
                  <div class="card-text pt-9">
                    <h3 class="gr-text-8 mb-2">Fathurur Said</h3>
                    <h4 class="gr-text-9 mb-0 line-height-1">
                      Full Stack Software Developer
                    </h4>
                    <p class="gr-text-10 mb-0 line-height-1">
                      Upgrading Genify's PFM tech stack.{" "}
                    </p>
                    <a
                      href="https://www.linkedin.com/in/fathursaid/"
                      target="blank"
                      className={iconClassName}
                    >
                      <i className="icon icon-logo-linkedin"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h2
          className="title gr-text-2 mb-9 text-center"
          style={{ marginTop: "5%" }}
          id="our-locations"
        >
          Our Locations
        </h2>

        <MapChart className="map" />
        <h2
          className="title gr-text-2 mb-9 text-center"
          style={{ marginTop: "5%" }}
        >
          Awards{" "}
        </h2>
        <div className="carouselSection value-prop" style={{ width: "45vw" }}>
          <div
            style={{
              textAlign: "center",
              color: "#161c2d",
              fontSize: "calc(16px + 1.4vw)",
              margin: "10% 0 5% 0",
            }}
          ></div>
          <Carousel>
            <div>
              <img
                alt="hackathon victory image"
                src={carouselimg1}
                style={{ height: "100%" }}
              />
              <p className="coursellegend">Genify won BSF virtual hackathon</p>
            </div>
            <div>
              <img
                alt="hackathon victory image"
                src={carouselimg2}
                style={{ height: "100%" }}
              />
              <p className="coursellegend">
                Genify won EGBank MINT Fintech Hackathon
              </p>
            </div>

            <div>
              <img
                alt="GIW's win image"
                src={carouselimg3}
                style={{ height: "100%" }}
              />
              <p className="coursellegend">
                Genify won GIW and was listed as top 10 startups
              </p>
            </div>
            <div>
              <img
                alt="4Paradigm victory image"
                src={carouselimg4}
                style={{ height: "100%" }}
              />
              <p className="coursellegend">Genify won 4Paradigm APEX prize</p>
            </div>
            <div>
              <img
                alt="Published research paper title"
                src={carouselimg5}
                style={{ height: "100%" }}
              />
              <p className="coursellegend">
                Published Research Paper on Transformer Based Recommended System{" "}
                <a
                  href="https://dl.acm.org/doi/10.1145/3404835.3464928"
                  target="_blank"
                >
                  Know more
                </a>
              </p>
            </div>
            <div>
              <img
                alt="participation in recommendation enging challenge"
                src={carouselimg6}
                style={{ height: "100%" }}
              />
              <p className="coursellegend">
                Participated in ADCB Recommendation Engine Challenge.{" "}
                <a
                  href="https://www.hub71.com/en/programs/the-outliers-adcb/"
                  target="_blank"
                >
                  Know more
                </a>
              </p>
            </div>
          </Carousel>
        </div>
        <h2
          className="title gr-text-2 mb-9 text-center"
          style={{ marginTop: "5%" }}
        >
          Timeline{" "}
        </h2>
        <div style={{ backgroundColor: "#fcfdfe", padding: "3% 0 5% 0" }}>
          <div
            style={{
              textAlign: "center",
              color: "#161c2d",
              fontSize: "calc(25px + 1.4vw)",
              marginBottom: "50px",
            }}
          >
            {" "}
          </div>
          <div>
            <Timeline />
          </div>
        </div>
      </PageWrapper>
    </>
  );
};

export default Product;
